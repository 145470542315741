import Vue from 'vue'
import Vuex from 'vuex'
import store from './vuex/store.js';
Vue.use(Vuex)
import App from './App.vue'
import router from './router/index'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import lazyLoad from './directives/lazy-load';
Vue.directive('lazyload', lazyLoad);

Vue.config.productionTip = false
Vue.prototype.$store = store;
// function loadAdSense() {
//   const script = document.createElement('script');
//   script.async = true;
//   script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1938740305510749';
//   document.head.appendChild(script);
// }

// loadAdSense();

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')