import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions';
import * as getters from './getters';

Vue.use(Vuex);
// 应用初始状态
const state = {
  language: '简体中文',
};
// 定义所需的 mutations
const mutations = {
  //存储用户信息
  setlanguage(state, pro) {
    state.language = pro;
  },
  clearlanguage(state, pro) {
    state.language = {};
  },
  
};

// 创建 store 实例
export default new Vuex.Store({
  actions,
  getters,
  state,
  mutations,
});