// src/directives/lazy-load.js
export default {
    inserted(el) {
      function loadImage() {
        const imageElement = Array.from(el.children).find(el => el.nodeName === 'IMG');
        if (imageElement) {
          imageElement.addEventListener('load', () => setTimeout(() => el.classList.add('loaded'), 100));
          imageElement.addEventListener('error', () => console.log('Error loading image'));
          imageElement.src = imageElement.dataset.src;
        }
      }
  
      function createObserver() {
        const options = {
          root: null,
          threshold: '0'
        };
  
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              loadImage();
              observer.unobserve(el);
            }
          });
        }, options);
  
        observer.observe(el);
      }
  
      if (!window['IntersectionObserver']) {
        loadImage();
      } else {
        createObserver();
      }
    }
  };
  